// Press "ESC"
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active menu-active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $("#off-canvas").removeClass("off-canvas-active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $("#main-nav ul li.has-subnav").removeClass("active");
        $("#main-nav ul li").removeClass("active");
        $('.active-hover').removeClass('active-c');
    }
});
$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});

// OffCanvas

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $(".search-wrapper, .search-close, .bookmarks-toggle, .overlay").removeClass("search-active");
    $("body").toggleClass("no-scroll-off-canvas");
    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});


$(".main-nav > ul > li").hover(function(){
    $(this).toggleClass("active");
});

$(".main-nav > ul > li").click(function(){
    $(this).addClass("active");
});

$(".gallery-nav > ul > li").click(function (){
    $(this).toggleClass("active");
});



// Galerien Slider
$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    asNavFor: '.slider-nav',
    responsive: [

        {
            breakpoint: 600,
            settings: {
                arrows: false,
            }
        },
    ]
});

$('.slider-nav').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    asNavFor: '.slider-for',
    centerMode: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
    ]
});